import React from 'react'
import Layout from '../../components/layout'
import Team from '../../components/team'

const Teams = () => (
  <Layout>
    <div>
      <h1>The Team</h1>
      <p>
        This is just a page showing routing /company/team
      </p>
       <p>
        I threw in the Team component below for the heck of it...
      </p>
      <Team />
    </div>
  </Layout>
)
 export default Teams
